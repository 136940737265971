.introduction {
    padding-top: $lh * 3;
    min-height: 100vh;
    display: flex;
    flex-wrap: none;

    @mixin media {
        min-height: 0;
    }
}

.introduction-title {
    flex: 0 0 72%;
    font-weight: normal;
    padding-top: $lh * 4;
    color: #3a3a3a;

    h1 {
        font-weight: normal;
        font-size: inherit;
        margin: 0;
        font-size: 5em;
        line-height: 1;
        letter-spacing: -0.03em;
    }

    @mixin media {
        padding-top: 0;
        flex: 0 0 100%;
    }
}

.introduction-links {
    padding-top: $lh * 4;
    display: block;
    flex: 0 0 28%;
    padding-left: $lh;
    display: none;

    @mixin media {
        flex: 0 0 100%;
        padding-top: $lh * 2;
        padding-left: 0;
    }
}

.introduction-link {
    text-align: center;
    display: block;
    font-size: 1.3em;
    padding: $lh;

    @mixin media {
        display: inline-block;
        font-size: 1em;
        margin-bottom: $lh / 2;
        margin-right: $lh / 2;
    }
}

.acqusition {
    background: #3d3df9;
}

.donors {
    background: #30f878;
}

.financial {
    background: #ffa780;
}

.thank {
    background: #feb8cf;
}

.t1 {
    display: inline-block;
    //color: #30f878;
    font-size: 1em;
   // transform: rotate(15deg) translate(0,-0.1em);
}

.t2 {
    display: inline-block;
    //color: #f2b7f9;
    font-size: 1em;
   // transform: rotate(-15deg) translate(-0.1em,0.2em);
}

.t3 {
    display: inline-block;
    //color: #3dc3a0;
    font-size: 1em;
    //transform: rotate(-15deg) translate(-0.2em,-0.2em);
}

.t4 {
    display: inline-block;
    //color: #00ffff;
    font-size: 1em;
    //transform: rotate(15deg) translate(-0.2em,0.2em);
}

.icon-scroll-down {
    position: absolute;
    bottom: $lh / 2;
    left: 50%;
    width: $lh * 4;
    transform: translate(-50%,0);
    transition: 500ms all;
    cursor: pointer;

    svg {
        width: 100%;
    }

    @mixin media {
        display: none;
        width: $lh * 3;
    }
    
}

.icon-scroll-down:hover {
    transform: translate(-50%,1em);
}

// front image
.introduction-image {
    flex: 0 0 100%;
    //background: red;
}
.int-carousel-container {
    position: relative;
    width: 100%;
    height: 0;
	display: block;
}

.int-carousel {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: block;
    //background: blue;
    padding: $lh * 2;
    padding-left: $lh * 4;
    padding-right: $lh * 4;

    @mixin media {
        padding: 0;
    }
}

.int-carousel-image {
    position: relative;
    width: 100%;
    height: 100%;
    background: yellow;
}

.int-title {
    display: block;
    width: 100%;
    position: absolute;
    z-index: 100;
    text-align: center;
    font-size: 5em;
    font-weight: normal;
    top: 5%;
    left: 0;
    //transform: translate(0, -50%);
    text-shadow: 1px 1px 8px rgba(0,0,0,0.2);
    color :black;
    user-select: none;   

    @mixin media {
        padding: 0;
        font-size: 3em;
    }
}