@font-face {font-display: swap; font-family: 'Gunkan';src: url('../fonts/favorit-regular.woff') format('woff');}

* {
    box-sizing: border-box;
}

body {
    font-family: Gunkan,Helvetica, Arial, sans-serif;
    font-size: 1.45vw;
    line-height: 1.28;
    margin: 0;
    text-rendering: optimizeLegibility;
    -webkit-font-smoothing: antialiased;

    @mixin media {
        font-size: 15px;
    } 

    // @media only screen and (min-width: 900px) {
    //     font-size: 18px;
    // }

    @media only screen and (min-width: 1500px) {
        font-size: 1.5vw;
    }

    // @media only screen and (maxx-width: 750px) {
    //     font-size: 18px;
    // }
}

html {
    scroll-behavior: smooth;
  }