@define-mixin media {
    @media only screen and (max-width: $firstbreakpoint) {
        @mixin-content;
  }
}

@define-mixin clear {
    margin: 0;
    padding: 0;
}

@define-mixin flex {
    display: flex;
    flex-wrap: wrap
}


@define-mixin media1250 {
    @media only screen and (max-width: 1250px) {
        @mixin-content;
  }
}

@define-mixin media900 {
    @media only screen and (max-width: 900px) {
        @mixin-content;
  }
}

@define-mixin media500 {
    @media only screen and (max-width: 500px) {
        @mixin-content;
  }
}