.project {
    width: 100%;
    padding: $lh;
    display: flex;
    flex-wrap: wrap;

    @mixin media {
        padding: $lh / 2;
        padding-bottom: $lh * 2;
        padding-top: $lh * 2;
    }    
}

.title {
    flex: 0 0 66%;
    //background: green;
    color: white;
    //padding: $lh / 2;
    width: 100%;
    padding-top: 0;
    font-size: 5em;
    font-weight: normal;
    position: absolute;
    z-index: 100;
    margin: 0;
    line-height: 1;
    text-align: center;
    vertical-align: middle;
    top: 50%;
    transform: translate(0, -50%);
    text-shadow: 1px 1px 6px rgba(0,0,0,0.5);

    @mixin media {
        font-size: 3em;
    }
}

.name {
    flex: 0 0 100%;
    font-weight: normal;
    font-size: inherit;
    margin: 0;
    opacity: 0.5;
    display: none;
}
.gallery {
    flex: 0 0 66%;
    width: 66%;
    padding: 2 * $lh;

    @mixin media {
        order: 1;
        flex: 0 0 100%;
        padding: $lh / 4;
        padding-left: 0;
        padding-right: 0;
    } 
}

.gallery-three {
    flex: 0 0 100%;

    .carousel-container {
        margin-left: 16%;
        width: 66%;
        display: inline-block;       
    }

}

.quote-container {
    p {
        margin-top: 0;
    }
    flex: 0 0 33%;
    padding-left: $lh;

    @mixin media {
        flex: 0 0 100%;
        padding: 0;
        order: 2;
        margin-top: $lh / 4;
    }
}

.quote-container-two {
    padding-left: 0;
    padding-right: $lh;

    @mixin media {
        padding: 0;
    }
}

.quote {
    padding: $lh;

    @mixin media {
        padding: $lh;
    }
}

.quote-map {
    background: rgb(236, 239, 241);
    width: 50%;
    margin-bottom: $lh;
    padding: $lh;

    @mixin media {
        width: 100%;
    }
}

.quote-credits {
    font-style: italic;
    //font-size: 0.8em;
    font-family: 'Times New Roman', Times, serif;
}

.note-container {
    width: 33%;
    padding: $lh;
    transform: translate(50vw, -5.7em);

    p {
        margin: 0;
    }

    @mixin media {
        flex: 0 0 100%;
        padding: $lh;
        transform: translate(0, 0);
        order: 3;
        margin-top: $lh / 2;
    }
}

.note-library-of-radiant-optimism {
    transform: translate(22vw, -5.7em);

    @mixin media {
        transform: translate(0, 0);
    }
}

.note-contsiner-two {
    transform: translate(13vw, -5.7em);

    @mixin media {
        flex: 0 0 100%;
        padding: $lh;
        transform: translate(0, 0);
    }
}


.white {
    color: white;
}

.color {
    //color: black;
}

.video-block {
    width: 100%;
    padding: $lh;
    transform: translate(0, -5.4em);

    iframe {
        width: 100%;
        height: 100%;
    }

    @mixin media {
        flex: 0 0 100%;
        padding: $lh / 2;
        transform: translate(0, 0);
    }
}

.video-container {
    position: relative;
    border-radius: 1em;
    overflow: hidden;
    width: 58vw;
    height: 33vw;
    left: 50%;
    transform: translate(-50%, 0);

    @mixin media {
        width: 90vw;
        height: 50vw;
        left: 0;
        left: 50%;
        transform: translate(-50%, 0);
    }
}

.play {
    color: white;
    text-align: center;
    background: magenta;
    border-radius: 1em;
    position: absolute;
    margin: auto;
    width: 58vw;
    height: 33vw;
    left: 50%;
    transform: translate(-50%, 0);
    z-index: 50;

    @mixin media {
        width: 90vw;
        height: 50vw;
        left: 0;
        left: 50%;
        transform: translate(-50%, 0);
    }
}

.play-label {
    position: relative;
    top: 50%;
    font-size: 10vw;
    transform: translate(0, -50%);
}

.video-block-night {
    width: 100%;
    padding: $lh;

    iframe {
        width: 100%;
        height: 100%;
    }
}

.play-nigh {
    color: black;
    text-align: center;
    background: #f0fc59;
    border-radius: 1em;
    position: absolute;
    margin: auto;
    width: 58vw;
    height: 33vw;
    left: 50%;
    transform: translate(-50%, 0);
    z-index: 50;

    @mixin media {
        width: 90vw;
        height: 50vw;
        left: 0;
        left: 50%;
        transform: translate(-50%, 0);
    }
}

.note-contsiner-night {
    transform: translate(0, 0);
    margin-bottom: 
}



.selected-country {
    padding: $lh / 4;
    padding-left: $lh / 2;
    padding-right: $lh / 2;
    background: lightgrey;
    display: inline-block;
    margin: $lh / 2;
    margin-left: 0;
    box-shadow: 0px 0px 10px 5px rgba(0,0,0,0.2);
    border-radius: $lh / 6;
}

.selected-country-button {
    background: yellow;
    cursor: pointer;
}