@import '_variables.scss';
@import '_mixins.scss';
@import '_flykity.scss';
@import '_default.scss';
@import '_project.scss';
@import '_navigation.scss';
@import '_introduction.scss';
@import '_footer.scss';



.carousel-container {
    position: relative;
    width: 100%;
    height: 0;
	display: block;
}

.carousel {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: block;
    //box-shadow: 0px 0px 10px 5px rgba(0,0,0,0.1);
}

.carousel-cell {
    width: 100%;
    height: 100%;
    margin-right: 10px;
    position: relative;
}

.carousel-image {
    position: absolute;
    width: 100%;
    height: 100%;
}

.bullets {
    line-height: 1;
    //letter-spacing: -0.1em;
    font-size: 0;
    transform: translate(0, 1vw);
    
    a {
        font-size: 10vw;
        display: inline-block;
        text-decoration: none;
        cursor: pointer;
        user-select: none;
        height: 1em;
    }

    a:hover {
        border-bottom: $lh / 18 solid;
    }

    @mixin media {
        display: block;
        margin-bottom: $lh / 2;
    } 
}

.bullets-two {
    //background: red;
    text-align: right;
}

.js-active-bullet {
    // color: rgba(0,0,0,0.0) !important;
    // -webkit-text-stroke-width: 1px;
    // -webkit-text-stroke-color: black;
    border-bottom: $lh / 18 solid;
}



//Artists
.artist {
    width: 100%;
    //background: red;
}
.artist-list {
    width: 100%;
    //font-size: 0.8em; 
    //background: blue;

}

.rt-td {
    a {
        color: black;
    }
}

.ReactTable {
    font-size: 0.8em; 
}

canvas {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 0;
}