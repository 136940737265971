nav {
    margin: 0;
    padding: 0;
    z-index: 900;
    position: relative;
}

.languages {

    position: fixed;
    top: 0;
    right: 0;
    padding: $lh / 2;
    background: $seccolor;
    margin: $lh / 4;
    width:  $lh * 2;
    text-align: center;

    li {
        display: inline-block;
        color: black;
    }

    a {
        color: black;
        text-decoration: none;
        text-transform: uppercase;
        font-size: 0.7em;
    }

    .active {
        display: none;
    }
}


.navbar-container {
    position: fixed;
    top: 0;
    left: 0;
    padding: $lh / 2;
    //background: blue;
    margin: 0;
}

.navbar {
    box-shadow: 0px 0px 10px 5px rgba(0,0,0,0.2);
    border-radius: $lh / 6;
    margin: 0;
    padding: 0;
    height: $lh;
    border-radius: $lh / 6;
    overflow: hidden;

    @mixin media {
        height: $lh * 2;
    }

    ul {
        margin: 0;
        padding: 0;
        width: 100%;
    }

    li {
        margin: 0;
        padding: 0;
        display: block;
        float: left;
        width: 5.555%;
        height: $lh;

        @mixin media {
            height: $lh * 2;
        }
    }
}


.navbar-slide {
    width: 100%;
    height: 100%;
    width: 5.555vw;
    height: $lh;
    display: block;
    //transition: 500ms all;

    @mixin media {
        height: $lh * 2;
    }
}

.js-nav-active {
    //background: $seccolor;
    background: white;
}

.navbar-slide:hover {
    background: $seccolor;
}

.nav-name {
    position: fixed;
    bottom: $lh / 2;
    left: $lh / 2;
    padding: $lh / 4;
    padding-left: $lh /2;
    padding-right: $lh /2;
    background: white;
    box-shadow: 0px 0px 10px 5px rgba(0,0,0,0.2);
    border-radius: $lh / 6;
}